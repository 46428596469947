import React from "react";

export const FinalAppFooter = (props) => {
  return (
    <div id="footer">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              width: "200px",
              display: "flex",
              marginTop:"10px",
              marginBottom:"50px",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <a href={props.data ? props.data.facebook : "/"}>
              <i className="fa fa-facebook"></i>
            </a>

            <a href={props.data ? props.data.twitter : "/"}>
              <i className="fa fa-twitter">
                {" "}
              </i>
            </a>

            <a href={props.data ? props.data.youtube : "/"}>
              <i className="fa fa-youtube"></i>
            </a>
          </div>
        </div>
        <div
          className="container text-center"
          style={{ paddingBottom: "10px" }}
        >
          Go to <a href="/privacy">Privacy Policy.</a>
        </div>

        <div className="container text-center">
          <p>&copy; 2024 Innov App Solutions. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
};
