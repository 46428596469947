import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Meet the Team</h2>
          <p>
            Our team's strength lies in unity and ambition. Each day, we're
            driven to surpass boundaries and create meaningful experiences for
            our community. Join us on a journey of growth and excellence.
          </p>
        </div>
        <div id="row">
          <div
            id="row"
            className="team-wrap"
  
          >
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.name}-${i}`}
                    className="col-md-3 col-sm-6 team"
                  >
                    <div className="thumbnail">
                      {" "}
                      <img src={d.img} alt="..." className="team-img" style={{borderRadius:'15px',marginBottom:'10px'}}/>
                      <div className="caption">
                        <h4>{d.name}</h4>

                        {d.job.split("\n").map((part, index) => (
                          <p>{part}</p>
                        ))}
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    </div>
  );
};
