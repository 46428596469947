import React, { useState, useEffect } from "react";
import axios from "axios";
import { doc, getDoc, setDoc } from 'firebase/firestore';
import PopupMessage from "../popup";
import { db } from "../firebaseConfig"; // Firebase configuration
import emailjs from "emailjs-com";

const initialState = {
  name: "",
  email: "",
  storeName: "",
  mobileNumber: "",
};
export const Contact = (props) => {
  const [ipAddress, setIpAddress] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [locationData, setLocationData] = useState({});
  const [deviceInfo, setDeviceInfo] = useState({});
  const [coordinates, setCoordinates] = useState({ lat: null, lon: null });
  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');

  const handleShowPopup = () => setShowPopup(true);
  const handleClosePopup = () => setShowPopup(false);

  const [loading, setLoading] = useState(true);
  const API_TOKEN = "29735a03383ee3"; // Replace with your IPinfo token

  useEffect(() => {
    // Fetch user's IP address using IPinfo API
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get(
          `https://ipinfo.io/json?token=${API_TOKEN}`
        );
        const { ip, city, region, country, org , loc} = response.data;
        console.error(response.data);
        setIpAddress(ip);
        setLocationData({
          city,
          region,
          country,
          organization: org,
          location:loc
        });
// Use the browser's geolocation for more accurate coordinates

        // Collect device/browser information
        setDeviceInfo({
          userAgent: navigator.userAgent,
          platform: navigator.platform,
          language: navigator.language,
        });

        // Check if the IP already exists as a document
        const ipDocRef = doc(db, 'formSubmissions', ip);
        const ipDocSnap = await getDoc(ipDocRef);

        if (ipDocSnap.exists()) {
          setIsSubmitted(true); // IP already submitted
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, [API_TOKEN]);

  const [{ name, email, storeName, mobileNumber }, setState] =
    useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitted) {
      // alert("You have already submitted the form.\nFore Further Assistance Please Contact the Numbers Below.\nHave a Nice Day!!");
      setTitle("Oops..");
      setMessage("You have already submitted the form.\nFor Further Assistance Please Contact the Numbers Below.\nHave a Nice Day!!");
      handleShowPopup();

      return;
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoordinates({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting location from browser:", error);
          // Fallback to IPinfo coordinates if geolocation is not available or rejected
          // const [lat, lon] = loc.split(",");
          // setCoordinates({ lat, lon });
        }
      );
    }
    if (coordinates['lat'] !=null && coordinates['lon'] != null){
    try {
      // Use the IP address as the document ID
      console.error(ipAddress);
      const ipDocRef = doc(db, 'formSubmissions', ipAddress);
      console.error('ipDocRef');
      await setDoc(ipDocRef, {
        ip: ipAddress,
        name,
        email,
        storeName,
        mobileNumber,
        location: locationData,
        device: deviceInfo,
        coordinates: coordinates, // Storing the latitude and longitude
        timestamp: new Date(),
      });

      // alert('Form submitted successfully.');
      setTitle("Success!!");
      setMessage("Form submitted successfully.\nWe will contact you shortly!\nLooking forward to working with you..");
      handleShowPopup();
      setIsSubmitted(true);
      emailjs
        .sendForm(
          "service_az0swoh",
          "template_v08ql0m",
          e.target,
          "HLD9_917WJMVoeApN"
        )
        .then(
          (result) => {
            console.log(result.text);
            clearState();
          },
          (error) => {
            console.log(error.text);
          }
        );
    } catch (error) {
      console.error('Error submitting form:', error);
    }} else {
      // Fallback to IPinfo coordinates if browser geolocation is not available
      // const [lat, lon] = loc.split(",");
      // setCoordinates({ lat, lon });
      alert("Please Allow Location and resubmit!");
    }
  
  };

  {
    /* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */
  }

  // };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="contact-text-fill">
            {/* <div style={{ display: "flex", flexDirection: "row" }}>  */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="contact-title-desktop-view">
                <h2 style={{ color: "white" }}>become a seller at woof</h2>
                <p>
                  Please fill out the form on the right to send us an email and
                  we will get back to you as soon as possible.
                </p>
              </div>
              <div className="contact-info">
                <img
                  className="avoid_offset"
                  style={{ marginLeft: "2vw", marginBottom: "1vh" }}
                  src={props.data ? props.data.img : "loading"}
                  alt="contact-us-img"
                />
                <h4 style={{ color: "white" }}>
                  {" "}
                  We'd love to hear it from you
                </h4>
                <p style={{ marginBottom: "2vh" }}> Contact us :</p>
                <span>
                  <i
                    style={{ marginBottom: "2vh" }}
                    className="fa fa-whatsapp"
                  ></i>{" "}
                  : +961 3 153498
                </span>
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> :{" "}
                    {props.data ? props.data.email : "loading"}
                  </span>
                </p>
              </div>
            </div>

            <div className="form-card contact-box">
              <div
                style={{
                  textAlign: "center",
                  alignContent: "center",
                }}
              >
                <h4 style={{ color: "#555" }}>
                  <b>Join us to sell on WOOF</b>
                </h4>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    id="storeName"
                    name="storeName"
                    // value={form.storeName}
                    placeholder="Store Name *"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div className="form-group">
                    <input
                      id="governorate"
                      name="governorate"
                      // value={form.governorate}
                      placeholder="Governorate * "
                      onChange={handleChange}
                      required
                    ></input>
                  </div>
                  <div className="form-group">
                    <input
                      id="area"
                      name="area"
                      // value={form.area}
                      placeholder="Area *"
                      onChange={handleChange}
                      required
                    ></input>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="website"
                    name="website"
                    // value={form.website}
                    placeholder="Store Website/Social Media *"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    // value={form.fullName}
                    placeholder="Full Name *"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="mobile"
                    name="mobile"
                    // value={form.mobile}
                    placeholder="Mobile (xxxx-xxxx) *"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    // value={form.email}
                    placeholder="Email "
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <textarea
                    id="additionalInfo"
                    name="additionalInfo"
                    // value={form.additionalInfo}
                    placeholder="Additional Info"
                    onChange={handleChange}
                  ></textarea>
                </div>
                <button type="submit">SUBMIT</button>
                <PopupMessage 
                  show={showPopup} 
                  onClose={handleClosePopup} 
                  title={title}
                  message={message}
                />



              </form>
            </div>
            <div className="contact-title">
              <h2 style={{ color: "white" }}>become a seller at woof</h2>
              <p>
                Please fill out the form on the right to send us an email and we
                will get back to you as soon as possible.
              </p>
            </div>
            {/* </div>  */}
          </div>
          {/* <div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    <li>
                      <a href={props.data ? props.data.facebook : "/"}>
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href={props.data ? props.data.twitter : "/"}>
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href={props.data ? props.data.youtube : "/"}>
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div id="footer">
        <div
          className="container text-center"
          style={{ paddingBottom: "10px" }}
        >
          Go to <a href="/privacy">Privacy Policy.</a>
        </div>

        <div className="container text-center">
          <p>&copy; 2024 Innov App Solutions. All Rights Reserved.</p>
        </div>
      </div> */}
    </div>
  );
};
/* <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>become a seller at woof</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div style={{display:'flex', flexDirection:'row'}}>
                  <input style={{width:'26.8vw'}}
                        type="store name"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Store Name"
                        required
                        onChange={handleChange}
                      />
                      <input style={{width:'26.8vw',marginLeft:'30px' }}
                        type="number"
                        id="mobileNumber"
                        name="email"
                        className="form-control"
                        placeholder="Mobile number (xxx_xxx)"
                        required
                        onChange={handleChange}
                      />
                  </div>
                  <textarea style={{marginTop:"15px"}}
                    type="text"
                    id="location"
                    name="location" 
                    className="form-control"
                    rows="4"
                    placeholder="Enter your location"
                    required
                    onChange={handleChange}
                    ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; 2024 Innov App Solutions. All Rights Reserved.</p>
        </div>
      </div> */
