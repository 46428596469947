import React, { useState } from "react";

// Popup component
const Popup = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div className="popup">
      <div 
      style={{display:'flex',flexDirection:'column',background:'white',padding:'10px',alignItems:'center',borderRadius:'15px'}}>
        <h2 style={{marginTop:'10px',marginBottom:'20px'}}>Download the App</h2>
        <div className="barcodes">
          <img src="img/appleBarcode.png" alt="AppStore" />
          <img src="img/PlayStoreBarcode.png" alt="PlayStore" />
        </div>
        <button  onClick={onClose} className="btn btn-custom btn-lg" style={{marginTop:'20px',marginBottom:'10px',width: '150px'}}>
          Close
        </button>
      </div>
    </div>
  );
};

// Header component
export const Header = (props) => {
  const [showPopup, setShowPopup] = useState(false);

  const handleButtonClick = () => {
    if (isMobile()) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        window.location.href = "https://play.google.com/store/apps/details?id=yourapp";
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.location.href = "https://apps.apple.com/us/app/yourapp/id123456789";
      }
    } else {
      setShowPopup(true);
    }
  };

  const isMobile = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  return (
    <header id="header" style={{paddingTop:'60px'}}>
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="#"
                  className="btn btn-custom btn-lg page-scroll"
                  onClick={handleButtonClick}
                >
                  Download the App
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup show={showPopup} onClose={() => setShowPopup(false)} />
    </header>
  );
};
