// PopupMessage.js
import React from 'react';
import './PopupMessage.css'; // Import your CSS for styling

const PopupMessage = ({ show, onClose,title, message }) => {
  if (!show) return null; // Don't render the popup if "show" is false
    console.error("WE RECEIVED THE MESSAGE: "+message);
  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-header">
          <h2>{title}</h2>
          {/* <button className="popup-close" onClick={onClose}>
            &times;
          </button> */}
        </div>
        <div className="popup-body">
          <p  style={{textAlign:'center'}}>{message}</p>
        </div>
        <div className="popup-footer">
          <button className="popup-button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupMessage;
