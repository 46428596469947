import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>
            You can download our app <b>WOOF</b> and enjoy scrolling and placing
            orders
          </p>
        </div>
        <div className="row">
          {/* <div className="portfolio-items"> */}
          <div className="wrapped-row-reversed">
            
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h2
                style={{
                  marginLeft: "2vh",
                  overflowWrap: "anywhere",
                  alignContent: "center",
                }}
              >
                {" "}
                Surf through our app with Light and Dark mode!{" "}
              </h2>
              <h3
                style={{
                  marginLeft: "2vh",
                  overflowWrap: "anywhere",
                  alignContent: "center",
                }}
              >
                {" "}
                Find all you need for your furry friend!{" "}
              </h3>
            </div>
            <img src="img/EmulatorView.png" className="emulator-sizing"></img>
            {/* {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."} */}
          </div>
        </div>
        <div className="row">
          {/* <div className="portfolio-items"> */}
          <div
            className="wrapped-row"
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h2
                style={{
                  marginLeft: "2vh",
                  overflowWrap: "anywhere",
                  alignContent: "center",
                }}
              >
                {" "}
                You can Become a Seller with WOOF!{" "}
              </h2>
              <h3
                style={{
                  marginLeft: "2vh",
                  overflowWrap: "anywhere",
                  alignContent: "center",
                }}
              >
                {" "}
                Enjoy your own app, and follow up on everything from your phone!{" "}
              </h3>
            </div>
            <img src="img/sellerEmulator.png" className="emulator-sizing"></img>
            {/* {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."} */}
          </div>
        </div>
      </div>
    </div>
  );
};
