import React from "react";
import { useLocation } from 'react-router-dom';

export const Navigation = (props) => {
  const location = useLocation();

  // Construct the base URL by removing the last segment if it's "privacy"
  const baseHref = location.pathname.includes( "privacy") ? location.pathname.replace('privacy', '') : location.pathname;
  

  
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>

          <a
            className="navbar-brand page-scroll"
            href={baseHref+"#page-top"}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              style={{ marginRight: "10px" }}
              src="img/ic_launcher.png"
              className="img-logo"
              alt=""
            />
            The Woof
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href={baseHref+"#features"} className="page-scroll">
                Features
              </a>
            </li>
            <li>
              <a href={baseHref+"#about"} className="page-scroll">
                About
              </a>
            </li>
            <li>
              <a href={baseHref+"#services"} className="page-scroll">
                Services
              </a>
            </li>
            <li>
              <a href={baseHref+"#portfolio"} className="page-scroll">
                Gallery
              </a>
            </li>
            {/* <li>
              <a href={baseHref+"#testimonials"} className="page-scroll">
                Testimonials
              </a>
            </li> */}
            {/* <li>
              <a href={baseHref+"#team"} className="page-scroll">
                Team
              </a>
            </li> */}
            <li>
              <a href={baseHref+"#contact"} className="page-scroll">
                Contact
              </a>
            </li>
            <li>
              <a href="/privacy" className="page-scroll">
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
