// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Replace with your own Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB_D0ORwEhQwNwtwJNEmBoOLj5sdAJPD7A",
    authDomain: "woof-customer-d0fd3.firebaseapp.com",
    projectId: "woof-customer-d0fd3",
    storageBucket: "woof-customer-d0fd3.appspot.com",
    messagingSenderId: "370703535809",
    appId: "1:370703535809:web:bd2c03cc856a413dbdce40",
    measurementId: "G-15VNTGN222"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
