import React from "react";

export const PrivacyPolicy = () => {
  return (
    <div style={{paddingTop:'15vh',paddingLeft:'400px',paddingRight:'400px',paddingBottom:'30px'}}>
      <div style={{display:"flex",color:'black',justifyContent:'center',paddingBottom:'70px'}}><h1>Privacy Policy</h1></div>
      <h3>Welcome to the privacy notice of The-Woof</h3>
      <h3>Last Updated: 29th May 2024.</h3>

      <p>
        InnovAppSolutions built the The-Woof app as a Commercial app. This
        service is provided by InnovSolutions and intended for use as it is.
        The-Woof respects your privacy and is committed to protecting your
        personal data. This privacy policy will inform you as to how we look
        after your personal data when you visit our Application This Privacy
        Policy is an integral part of the Terms and Conditions. By accepting the
        Terms, you accept the Privacy Policy. By using the Service on any and
        all platforms, you agree to the collection and use of information in
        accordance with this Privacy Policy. If you choose to use our Service,
        then you agree to the collection and use of information in relation to
        this policy. The Personal Information that we collect is used for
        providing and improving the Service. We will not use or share your
        information with anyone except as described in this Privacy Policy. The
        terms used in this Privacy Policy have the same meanings as in our Terms
        and Conditions, which is accessible at The-Woof unless otherwise defined
        in this Privacy Policy.
      </p>
      <h1>Purpose of this privacy policy</h1>
      <p>
        This privacy policy aims to give you information on how The-Woof
        collects and processes your personal data through your use of our
        platforms, including any data you may provide through the platforms when
        you purchase a product or services from The-Woof. Our platforms are not
        intended for children, and we do not knowingly collect data relating to
        children. It is important that you read this privacy policy together
        with any other privacy policy or fair processing policy we may provide
        on specific occasions when we are collecting or processing personal data
        about you so that you are fully aware of how and why we are using your
        data. This privacy policy supplements other notices and privacy policies
        and is not intended to override them.
      </p>

      <h1>The data we collect about you</h1>
      <p>
        We may collect, use, store and transfer different kinds of personal data
        about you which we have grouped together as follows: Identity Data
        includes first name, maiden name, last name, username or similar
        identifier and date of birth. Contact Data includes delivery address,
        email address and telephone numbers. Transaction Data includes details
        about products you have purchased from us. Technical Data includes
        internet protocol (IP) address, your login data, browser type and
        version, time zone setting and location, operating system and platform,
        and other technology on the devices you use to access our platforms.
        Profile Data includes your username and password, purchases or orders
        made by you, your interests, preferences, feedback and survey responses.
        Usage Data includes information about how you use our platforms and
        products. We do not collect any Special Categories of Personal Data
        about you (this includes details about your race or ethnicity, religious
        or philosophical beliefs, political opinions, trade union membership,
        information about your health, and genetic and biometric data). It is
        important that the personal data we hold about you is accurate and
        current. Please keep us informed if your personal data changes during
        your relationship with us.
      </p>

      <h1>How is your personal data collected?</h1>
      <p>
        We use different methods to collect data from and about you including
        through: Direct interactions: You may give us your Identity and Contact
        by filling in forms or by corresponding with us by post, phone, email or
        otherwise. This includes personal data you provide when you: apply for
        our products. create an account on our platforms. give us feedback or
        contact us.
      </p>

      <h1>Personal Contact Information</h1>
      <p>
        The-Woof may collect the following information from users of our
        platforms: first name, last name, street address, area and city, phone
        and contact numbers, e-mail address, (collectively, “Personally
        Identifiable Information”). In addition, The-Woof may collect
        information regarding The-Woof account holders’ past purchase orders,
        customer service inquiries and platform feedback. IP Addresses are the
        Internet Protocol addresses of the computers that you are using. Your IP
        Address is automatically assigned to the computer that you are using by
        your Internet Service Provider (ISP). This number is identified and
        logged automatically upon signing up on our application and logging in.
        Collecting IP Addresses is standard practice on the Internet and is done
        automatically by many websites.
      </p>

      <h1>Navigation and Click stream Data</h1>
      <p>
        As you browse through our apps, we gather navigational and click stream
        data that shows which categories you have visited. This information will
        not reveal your identity or be linked to you personally. We use this
        information to complete, support and analyze your purchases from us; to
        facilitate and improve your use of the application; to comply with any
        requirements of law; to serve and for internal purposes. This
        information may be disclosed only to our staff and to third parties
        involved in the completion of your transaction or the delivery of your
        order..
      </p>

      <h1>How We Use Your Information</h1>
      <p>
        We will only use your personal data when the law allows us to. Most
        commonly, we will use your personal data in the following circumstances:
        Where we need to perform the contract, we are about to enter into or
        have entered into with you. Where it is necessary for our legitimate
        interests (or those of a third party) and your interests and fundamental
        rights do not override those interests. Where we need to comply with a
        legal obligation. Generally, we do not rely on consent as a legal basis
        for processing your personal data although we will get your consent
        before sending third party direct marketing communications to you via
        email or text message. You have the right to withdraw consent to
        marketing at any time by contacting us. Personally Identifiable
        Information The-Woof uses PII to create users' The-Woof accounts, to
        communicate with users about The-Woof services, to offer users
        additional services, promotions, and special offers and to charge for
        purchases made through The-Woof. The-Woof may also use PII to enforce
        The-Woof terms of use and services. We may disclose PII: a) To our
        third-party service providers who provide services such as website
        hosting, payment processing, order fulfillment, infrastructure
        provision, IT services, customer service, e-mail delivery services,. As
        required to fulfill your purchase (e.g., to our staff to enable them to
        fulfill your order). b) To identify you to our customer service to whom
        you send messages regarding Application-related content through the
        Application. c) To third parties’ sponsors of Promotions (irrespective
        of whether such Promotions are hosted by us), or otherwise in accordance
        with the rules applicable to such Promotion. You should carefully review
        the rules, if any, of each Promotion in which you participate, as they
        may contain additional important information about a sponsor's or other
        third parties’ use of your PII. d) To the extent that the terms and
        conditions of such rules concerning the treatment of your PII conflict
        with this Privacy Policy, the terms and conditions of such rules shall
        prevail. e) To an affiliate or other third party in the event of any
        reorganization, merger, sale, joint venture, assignment, transfer or
        other disposition of all or any portion of our business, assets, or
        stock (including without limitation in connection with any bankruptcy or
        similar proceedings) as we believe to be appropriate: 1) under
        applicable law including laws outside your country of residence. 2) to
        comply with the legal process. 3) to respond to requests from public and
        government authorities including public and government authorities
        outside your country of residence. 4) to enforce our terms and
        conditions. 5) to protect our operations or those of any of our
        affiliates. 6) to protect our rights, privacy, safety or property,
        and/or that of our affiliates, you or others and 7) to permit us to
        pursue available remedies or limit the damages that we may sustain. User
        generated content posted through the site such as service/product
        reviews and certain social networking preferences (e.g., pages you
        "Like" or "Recommend") may be viewed by the general public. Accordingly,
        The-Woof cannot ensure the privacy of any PII included in such user
        generated content.
      </p>

      <h1>Non-Personally Identifiable Information.</h1>
      <p>
        Since Non-PII does not personally identify you, we may use such
        information for any purpose. In addition, we reserve the right to share
        such Non PII, which does not personally identify you, with our
        affiliates and with other third parties, for any purpose. In some
        instances, we may combine Non-PII with PII (such as combining your name
        with your geographical location). If we combine any Non-PII with PII,
        the combined information will be treated by us as PII hereunder if it is
        so combined.
      </p>

      <h1>IP Addresses</h1>
      <p>
        We may use and disclose IP Addresses for all the purposes for which we
        use and disclose PII. Please note that we treat IP Addresses, server log
        files and related information as Non-PII, except where we are required
        to do otherwise under applicable law. For individuals subject to the
        General Data Protection Regulation of the European Union (“EU”) or the
        United Kingdom of Great Britain and Northern Ireland (“UK”), our
        processing of your personal information is justified on the following
        legal bases: the processing is necessary to perform a contract with you
        or take steps to enter into a contract at your request; the processing
        is necessary for us to comply with a relevant legal obligation; the
        processing is in our legitimate interests, subject to your interests and
        fundamental rights, and notably our legitimate interest in using
        applicable data to conduct and develop our business activities; or you
        have consented to the processing.
      </p>

      <h1>Your Email Address</h1>
      <p>
        To register for an account, we require you to supply us with your email
        address, or other information needed to contact you online and over the
        phone. We use your e-mail address and other contact information you
        provide us with to confirm your order and to communicate with you in
        case of an order problem, so one cannot register without an e-mail
        address. If you supply us with your email address, you may later access,
        update, modify, and delete any inaccuracies by accessing your account
        through my account information on our platforms. You may also choose
        simply not to provide us with your email address; however, you will not
        be able to register on the platforms, place orders or access certain
        features. We communicate part of your contact information (name,
        surname, order delivery address and phone number) to our staff and to
        third parties involved in the delivery of your order or the analysis and
        support of your use of the platforms. Nothing contained in this Policy
        shall be construed as imposing upon The-Woof (InnovAppSolutions) any
        responsibility of any kind to any third party. The Customer shall
        provide third-party/recipient information including but not limited to
        the name, address, email, or phone-number at their own risk. We do not
        sell and will not divulge your personal contact information to third
        parties other than as specified in this Data Privacy Policy and Cookie
        Policy without your permission unless we are legally entitled or obliged
        to do so (for example, if required to do so by Court order or for the
        purposes of prevention of fraud or other crime).
      </p>

      <h1>Order Placement Information</h1>
      <p>
        Personal information required during the order process includes name,
        address, phone number, email address as well as the recipient details
        and other similar information used to identify you and complete an
        order. Personal information collected at our site will be shared with
        our staff when processing your order. We have an agreement with all our
        staff, restricting disclosure or further processing of personal
        information provided to them by us. Your personal information will be
        used to notify you of your order status. Personal information will not
        be shared with any of our business partners or affiliates without your
        permission. It is treated as confidential, and will not be disclosed to
        outside parties, unless compelled by applicable legislation. We only
        send marketing emails to those people who have opted-in or specifically
        requested to receive this information.
      </p>

      <h1>Protection of Your Personal Information</h1>
      <p>
        We use various technical and management practices to help protect the
        confidentiality, security and integrity of data stored on our system.
        Our servers use Secure Sockets Layer (SSL) and encryption technology
        that works with our platforms.
      </p>

      <h1>How long is your information stored?</h1>
      <p>
        We will only retain your personal data for as long as reasonably
        necessary to fulfill the purposes we collected it for, including for the
        purposes of satisfying any legal, regulatory, tax, accounting or
        reporting requirements. We may retain your personal data for a longer
        period in the event of a complaint or if we reasonably believe there is
        a prospect of litigation in respect to our relationship with you. To
        determine the appropriate retention period for personal data, we
        consider the amount, nature and sensitivity of the personal data, the
        potential risk of harm from unauthorized use or disclosure of your
        personal data, the purposes for which we process your personal data and
        whether we can achieve those purposes through other means, and the
        applicable legal, regulatory, tax, accounting, or other requirements. In
        some circumstances we will anonymize your personal data (so that it can
        no longer be associated with you) for research or statistical purposes,
        in which case we may use this information indefinitely without further
        notice to you.
      </p>

      <h1>Advertisements</h1>
      <p>
        Advertisements for our products and services may appear on third party
        websites around the Internet. Some websites and services deliver these
        advertisements based upon data that has been previously collected about
        your past behavior online. For example, if you have viewed websites
        about gift baskets, these websites or services may display to you an
        advertisement for our gift baskets. This kind of targeted advertising is
        sometimes called interest-based or online behavioral advertising. We do
        not control such third-party websites or services on which these
        advertisements appear. We do not control how they collect and use data
        about your online activities. Any information that these third parties
        collect via cookies and action tags is completely anonymous and does not
        contain any personal contact information.
      </p>

      <h1>Marketing</h1>
      <p>
        It is very important to us that we provide you with the highest level of
        service. In order to do this, we may send you details of products,
        services, discounts, promotions and campaigns, which we think may be of
        interest to you. We may send you marketing communications with your
        consent. If at any time you do not wish to receive marketing
        communications via email or participate in our ad-customization program,
        you may choose to unsubscribe from marketing communications by contact
        us via e-mail or logging onto your account to change your relevant
        settings from the ’profile’ section which is accessible from the user
        main page on our platforms. We do not sell or rent your personal
        information to third parties for their marketing purposes without your
        explicit consent. We have relationships with third parties who may
        collect or receive information directly from the cookies placed by our
        service on your computer to assist in analyzing our business and
        customers, and to give credit to other sites that direct traffic to our
        platforms for marketing purposes. We may disclose your information to
        our staff and to third parties involved in the delivery of your order
        and may authorize them to use such information for our benefit, for
        instance, to process your order and to better communicate and provide
        appropriate offers to you and your Product recipient(s). We require all
        third parties to respect the security of your personal data and to treat
        it in accordance with the law. We do not allow our third-party service
        providers to use your personal data for their own purposes and only
        permit them to process your personal data for specified purposes and in
        accordance with our instructions. We may disclose your information if
        necessary to protect our legal rights, if the information relates to
        actual or threatened harmful conduct, or we have good faith belief that
        such action is necessary to: (1) conform to the requirements of law or
        comply with governmental orders, court orders, or legal process served
        on us or (2) to protect and defend our property or other rights, the
        users of the platforms or the public. This includes exchanging
        information with other companies and organizations for fraud protection
        and credit risk protection.
      </p>

      <h1>Links to Other Sites</h1>
      <p>
        This Service may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by us. Therefore, we strongly advise you
        to review the Privacy Policy of these websites. We have no control over
        and assume no responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
      </p>

      <h1>Account Deletion, Data Deletion and Rights</h1>
      <p>
        You are able to delete your account from your “Profile” on mobile apps.
        Once your account has been deleted, all processed data shall be erased
        automatically. You will not be able to reactivate your account or regain
        access. We will not retain or restore any of your data. To make future
        purchases on Petsmarket, you will have to set up a new account. Once you
        request to have your account deleted, we’ll process your request and
        deactivate your account. This process may take up to 30 days. Under
        certain circumstances, you have rights under data protection laws in
        relation to your personal data. This will extend to: The right to be
        informed: You have the right to be provided with information about how
        we use your information. Therefore, we’re providing you with the
        information in this policy. The right of access: You have the right to
        obtain access to your information. This will enable you, for example, to
        check that we’re using your information in accordance with data
        protection laws. If you wish to access the information we hold about you
        in this way, please get in touch with us (see Contact Us section). The
        right to rectification: You are entitled to have your information
        corrected if it is inaccurate or incomplete. You can request that we
        rectify any errors in information that we hold by contacting us (see
        Contact Us section). The right to erasure: This enables you to request
        the deletion or removal of certain of the information that we hold about
        you if it is inaccurate or incomplete by contacting us (see Contact Us
        section). The right to withdraw consent: If you have given your consent
        to anything we do with your information (i.e., we rely on consent as a
        legal basis for processing your information), you have the right to
        withdraw that consent at any time. You can do this by contacting us (see
        Contact Us section). Withdrawing consent will not however make unlawful
        our use of your information while consent has been apparent. The right
        to Object to processing of your personal Data: This enables you to ask
        us to suspend the processing of your personal data in the following
        scenarios: a) If you want us to establish the data's accuracy. b) Where
        our use of the data is unlawful, but you do not want us to erase it. c)
        Where you need us to hold the data even if we no longer require it as
        you need it to establish, exercise or defend legal claims. d) You have
        objected to our use of your data, but we need to verify whether we have
        overriding legitimate grounds to use it.
      </p>

      <h1>Data Security</h1>
      <p>
        The security of your personal information is important to us. We use
        credible organizational, technical, and administrative measures to
        protect PII under our control. Unfortunately, no data transmission over
        the Internet or data storage system can be guaranteed to be 100% secure.
        Although we make reasonable efforts to safeguard personal information
        once we receive it, we cannot warrant the security of information we
        receive. Please do not send us sensitive information through email. If
        you have reason to believe that your interaction with us is no longer
        secure (for example, if you feel that the security of any account you
        might have with us has been compromised), you must immediately notify us
        of any unauthorized activity by contacting us via the email found in the
        "Contact Details" section available on all our platforms (note that if
        you choose to notify us via physical mail, it will take a longer
        duration of time for us to respond to the issue raised). Portions of the
        goods and services we supply may require registration and log-in
        processes in which you will select a user ID and password (collectively,
        the "Password"). You are responsible for maintaining the confidentiality
        of any Password and for all activities that occur using your Password,
        whether or not authorized by you.
      </p>

      <h1>Changes to Policy</h1>
      <p>
        We reserve the right to update or modify our platforms or this Policy at
        any time and from time to time without prior notice. Any revisions to
        this Policy will be effective upon posting. The most recent version of
        this Policy is available at www.the-woof.net You should review this
        Policy periodically to ensure you are aware of any changes, through any
        of our platforms. If you object to any changes to this Policy, you must
        cease using our platforms. This Policy was last updated on the date
        indicated at the top of this page. Your continued use of our platforms
        after any changes or revisions to this Policy indicates your agreement
        to the terms of the revised Policy.
      </p>

      <h1>Contact Us</h1>
      <p>
        If you have any questions or suggestions about our Privacy Policy, do
        not hesitate to contact us at <a href="mailto:support@the-woof.net">support@the-woof.net</a>
      </p>
    </div>
  );
};
